import { useScrollbar } from '@14islands/r3f-scroll-rig'
import { useGSAP } from '@gsap/react'
import { AnimatePresence, motion } from 'framer-motion'
import gsap from 'gsap'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

import { Column, Grid } from '~/components/Grid'
import { Navigation } from '~/lib/sanity.queries'
import { getStaggeredDelay, urlFor } from '~/utils'

import Button from './Button'
import { EXPO_OUT } from './constants'
import useMediaQuery from './hooks/useMediaQuery'
import { ChevronDownIcon } from './icons/ChevronDownIcon'
import { EmailIcon } from './icons/EmailIcon'
import { TelegramOutlineIcon } from './icons/TelegramOutlineIcon'
import { TwitterIcon } from './icons/TwitterIcon'
import StaggerText from './StaggerText'

const subNavVariants = {
  open: {
    height: 'auto',
    transition: {
      duration: 1,
      ease: EXPO_OUT,
    },
  },
  closed: {
    height: 0,
    transition: {
      duration: 1,
      ease: EXPO_OUT,
    },
  },
}

export const Header = ({ navigation }: { navigation: Navigation }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const headerRef = useRef(null)
  const [hoveredIndex, setHoveredIndex] = useState<number | string | null>(null)
  const [activeSubnav, setActiveSubnav] = useState<string | null>(null)
  const scroll = useScrollbar()
  const tlIn = useRef<gsap.core.Timeline>()
  const isMobile = useMediaQuery('(max-width: 1024px)')

  const { contextSafe } = useGSAP(
    () => {
      if (isMobile) {
      tlIn.current = gsap
        .timeline({
          paused: true,
          immediateRender: true,
          defaults: { ease: 'linear', duration: 1.5 },
        })
        .to(
          '#header-overlay',
          {
            autoAlpha: 1,
          },
          0,
        )
        .to(
          '#mobile-nav-bg',
          {
            translateY: 0,
            pointerEvents: 'auto',
          },
          0,
        )
        .to(
          'nav',
          {
            autoAlpha: 1,
          },
          0,
        )
        .fromTo(
          '.nav-item',
          {
            translateY: '100%',
            pointerEvents: 'none',
          },
          {
            translateY: 0,
            pointerEvents: 'auto',
          },
          0,
        )
        .fromTo(
          '.nav-item-social',
          {
            translateY: '100%',
            autoAlpha: 0,
            pointerEvents: 'none',
          },
          {
            translateY: 0,
            autoAlpha: 1,
            pointerEvents: 'auto',
          },
          0,
        )

        // set initial state of all items in tlIn
        tlIn.current.progress(0)
      } else {
        gsap.set('nav', { opacity: 1 })
      }
    },
    { scope: headerRef.current, dependencies: [isMobile], revertOnUpdate: true },
  )

  const playIntro = contextSafe(() => {
    tlIn.current.pause()
    gsap.to(tlIn.current, { time: tlIn.current.duration(), ease: 'expo.out' })
  })

  const playOutro = contextSafe(() => {
    tlIn.current.pause()
    gsap.to(tlIn.current, { time: 0, ease: 'expo.out' })
  })

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => {
      if (prevIsMenuOpen) {
        playOutro()
      } else {
        playIntro()
      }

      if (!prevIsMenuOpen && scroll?.__lenis) {
        scroll.__lenis.stop()
        return !prevIsMenuOpen
      }

      setActiveSubnav(null)
      scroll?.__lenis && scroll.__lenis.start()

      return !prevIsMenuOpen
    })
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsMenuOpen(false)
        scroll?.__lenis && scroll.__lenis.start()
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [scroll])

  return (
    <header
      ref={headerRef}
      className="fixed top-0 z-20 flex w-full h-header md:h-header-lg pointer-events-auto
                border-b border-grey-100 backdrop-blur-xl"
    >
      <div
        id="header-overlay"
        className={`absolute top-0 left-0 z-10 w-full h-full 
                bg-opacity-70 transition-opacity 
                  duration-200 backdrop-blur-xl`}
      ></div>
      <Grid className="w-full z-10">
        <Column className="flex col-span-12 lg:items-center lg:justify-center">
          <Link className="z-10 w-auto lg:flex-1" href="/" aria-label="Home">
            <h1 className="inline-flex items-center gap-2 h-full">
              <Image
                src={urlFor(navigation.logo.asset)
                  .height(100)
                  .auto('format')
                  .url()}
                width={200}
                height={200}
                alt="NodeAI"
                priority
                className={`w-auto h-5 sm:h-6 inline-block transition ${isMenuOpen ? 'brightness-0 duration-100 delay-0' : 'duration-200 delay-500'}`}
              />
              <span className="sr-only">NodeAI</span>
            </h1>
          </Link>

          <div
            id="mobile-nav-bg"
            className={`fixed inset-0 w-full h-screen bg-mobile-nav pointer-events-none lg:hidden -translate-y-full`}
          ></div>

          <nav
            style={{ opacity: 0, pointerEvents: 'none'}}
            className={`fixed inset-0 z-20 flex items-start justify-center
                           lg:items-center lg:justify-center lg:static
                           text-black lg:text-white
                           transition lg:bg-transparent lg:flex-1 lg:flex 
                           px-4 md:px-16 lg:px-0 group/nav 
                           h-screen lg:h-auto
                          
                           `}
            aria-label="Main navigation"
          >
            <ul
              className="flex flex-col items-start justify-start w-full h-full
                           lg:flex-row lg:items-center lg:justify-center 
                           pt-header-lg lg:pt-0 gap-y-4 lg:gap-y-0 lg:gap-x-10 
                           xl:gap-x-16"
            >
              {navigation.main.map((item, index) => (
                <li
                  className={`relative flex-shrink-0 z-20 
                                lg:pointer-events-auto overflow-hidden
                                lg:overflow-visible`}
                  key={index}
                >
                  {item.subitems ? (
                    <div className="relative group">
                      <div
                        className={`nav-item h-10 lg:h-auto lg:group-hover/nav:text-grey  lg:group-hover:text-white
                                        ${
                                          activeSubnav === item.page.text
                                            ? 'lg:text-grey'
                                            : 'text-black lg:text-white'
                                        }
                                      `}
                      >
                        <div
                          className={`flex items-center gap-2 text-38 mono-large 
                                        lg:text-sm transition-colors duration-300 ease-out-expo
                                        lg:group-hover/link:text-white`}
                          aria-label={item.page.text}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                          onClick={() =>
                            activeSubnav === item.page.text
                              ? setActiveSubnav(null)
                              : setActiveSubnav(item.page.text)
                          }
                        >
                          <StaggerText
                            className="h-full"
                            isHovered={hoveredIndex === index}
                            text={item.page.text}
                          />
                          <ChevronDownIcon
                            className={`transform transition-transform
                                                      duration-300 
                                                      md:group-hover:rotate-180 
                                                      lg:group-hover/link:text-white 
                                                      ${
                                                        activeSubnav ===
                                                        item.page.text
                                                          ? 'rotate-180'
                                                          : 'rotate-0'
                                                      }`}
                          />
                        </div>
                      </div>

                      <AnimatePresence>
                        <motion.div
                          initial={{ height: 0 }}
                          animate={
                            activeSubnav === item.page.text ? 'open' : 'closed'
                          }
                          exit={{ height: 0 }}
                          variants={subNavVariants}
                          className={`lg:absolute lg:top-0 lg:left-0 
                                                lg:py-10 lg:pointer-events-none 
                                                lg:group-hover:pointer-events-auto 
                                                static overflow-hidden lg:overflow-visible 
                                                pointer-events-none z-30 
                                                ${
                                                  activeSubnav ===
                                                  item.page.text
                                                    ? 'static pointer-events-auto'
                                                    : ''
                                                }`}
                        >
                          <ul
                            className={`flex flex-col items-start justify-center 
                                          h-auto gap-4 lg:bg-black bg-opacity-10 
                                          p-4 lg:p-5 pt-3 lg:opacity-0 
                                          lg:group-hover:opacity-100 
                                          transition-opacity lg:duration-300 
                                          transform border-grey-100 
                                          lg:border rounded-lg backdrop-blur-xl 
                                          ${
                                            activeSubnav === item.page.text
                                              ? 'opacity-100 delay-300'
                                              : 'opacity-0 delay-0 duration-0'
                                          }`}
                          >
                            {item.subitems.map((subitem, subindex) => (
                              <li
                                className="relative transition-colors 
                                             duration-300 overflow-hidden"
                                key={subindex}
                              >
                                <Link
                                  className={`flex transition duration-700 
                                                  ease-out-expo transform 
                                                  lg:translate-y-0 
                                                  mono-large text-21 lg:text-sm 
                                                  whitespace-nowrap 
                                                  lg:hover:text-white 
                                                  ${
                                                    activeSubnav ===
                                                    item.page.text
                                                      ? 'translate-y-0'
                                                      : 'translate-y-full'
                                                  }`}
                                  href={subitem.url}
                                  aria-label={subitem.text}
                                  onMouseEnter={() =>
                                    setHoveredIndex(subitem.text)
                                  }
                                  onMouseLeave={() => setHoveredIndex(null)}
                                  style={{
                                    transitionDelay: `${getStaggeredDelay({
                                      index: subindex,
                                      length: item.subitems.length,
                                      reverse: activeSubnav === item.page.text,
                                      staggerIn: 100,
                                      staggerOut: 50,
                                      baseDelayIn: 200,
                                    })}ms`,
                                  }}
                                >
                                  <StaggerText
                                    className="h-full"
                                    isHovered={hoveredIndex === subitem.text}
                                    text={subitem.text}
                                  />
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      </AnimatePresence>
                    </div>
                  ) : (
                    <div className="h-10 lg:h-auto lg:group-hover/nav:text-grey transition duration-300 ease-out-expo">
                      <Link
                        className={`nav-item block
                                      text-38 mono-large lg:text-sm
                                      lg:hover:text-white`}
                        href={item.page.url}
                        aria-label={item.page.text}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <StaggerText
                          className="h-full"
                          isHovered={hoveredIndex === index}
                          text={item.page.text}
                        />
                      </Link>
                    </div>
                  )}
                </li>
              ))}
              <li
                className={`flex-shrink-0 relative mt-6 z-50 overflow-hidden lg:hidden`}
              >
                <div className="nav-item h-full">
                  <Button
                    href={navigation.mainCta.url}
                    aria-label={navigation.mainCta.text}
                    variant="black"
                    asLink
                  >
                    {navigation.mainCta.text}
                  </Button>
                </div>
              </li>
              <li
                className={`nav-item-social flex flex-col items-start justify-end w-full 
                             pb-6 gap-y-3 mt-auto h-full lg:hidden`}
              >
                <div className="flex items-center justify-center gap-x-3 text-black">
                  <Link
                    className="transition duration-300 h-5 hover:opacity-50"
                    href="https://twitter.com/NodeAIETH"
                    aria-label="Twitter"
                  >
                    <TwitterIcon className="h-full" />
                  </Link>
                  <Link
                    className="transition duration-300 h-6 hover:opacity-50"
                    href="https://t.me/nodeaieth"
                    aria-label="Telegram"
                  >
                    <TelegramOutlineIcon className="h-full w-full" />
                  </Link>
                  <Link
                    className="transition duration-300 h-6 hover:opacity-50"
                    href="mailto:hello@nodes.ai"
                    aria-label="Email"
                  >
                    <EmailIcon className="h-full" />
                  </Link>
                </div>
                <div className="text-left text-grey mono-small">
                  ©2024 All Rights Reserved
                </div>
              </li>
            </ul>
          </nav>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Button
              aria-label={navigation.mainCta.text}
              pin="right"
              asLink
              href={navigation.mainCta.url}
            >
              {navigation.mainCta.text}
            </Button>
          </div>

          <div className="z-50 ml-auto h-full lg:hidden">
            <button
              className="p-2"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-label="Toggle menu"
            >
              <div
                className={`flex flex-col items-end justify-between h-2 
                              ${isMenuOpen ? 'open' : ''}`}
              >
                <span
                  className={`block w-10 h-px transition duration-300 
                                ${
                                  isMenuOpen
                                    ? 'bg-black scale-x-110'
                                    : 'bg-white delay-500'
                                } 
                                origin-right`}
                />
                <span
                  className={`block w-10 h-px transition duration-300 
                                ${
                                  isMenuOpen
                                    ? 'bg-black scale-x-110 -translate-y-2'
                                    : 'bg-white scale-x-75 delay-500'
                                } 
                                origin-right`}
                />
              </div>
            </button>
          </div>
        </Column>
      </Grid>
    </header>
  )
}
