export const HOME_SECTIONS = [
  'Features',
  'Our Services',
  'How it Works',
  'GPU Power',
]

export const PAGE_TITLE_PREFIX = 'Node AI |'

export const EXPO_OUT = [0.19, 1, 0.22, 1]
export const QUINT_OUT = [0.22, 1, 0.36, 1]

export const ECOSYSTEM_SECTIONS: EcosystemSection[] = [
  'Showcase',
  'Tools',
  'Deployed',
  'Providers',
]
export type EcosystemSection = 'Showcase' | 'Tools' | 'Deployed' | 'Providers'
